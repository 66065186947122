<template>
    <div class="home">
        <div v-if="base.roadType == 1" class="head-img" @click="previewImg(info.picUrl)">
            <van-image
                class="img"
                radius="5"
                
                src = "http://47.100.124.36:20080/resources/screenShots/9498A203E3FE/183-QC202407160004-2024_07_19_14_17_20.png"
            />
            <!-- :src="info.picUrl" -->
        </div>
        <div class="body-info">

            <div class="title">
                基础信息
            </div>
            <div class="content">
                <van-row class="block">
                    <van-col span="24">点位：{{base.roadName || '名称未设置'}}</van-col>
                </van-row>
            </div>

            <div v-if="base.roadType == 2" class="flex-between">
                <div class="title">
                    实时视频
                </div>
            </div>
            <div v-if="base.roadType == 2" class="content">
                <video width="320" height="240" controls  ref="video"></video>
            </div>


            <div v-if="base.roadType == 2" class="flex-between">
                <div class="title">
                    气象信息
                </div>
                <div class="time">
                    <van-icon name="clock-o" />
                    {{info.uploadTime || '0000-00-00 00:00:00'}}
                </div>
            </div>
            <div v-if="base.roadType == 2" class="content">
                <van-row class="block">
                    <van-col span="12">状态：
                        <span v-if="info.resultRevise === '1' || info.resultRevise === '2' || info.resultRevise === '3'" class="important-font">
                            {{info. resultRevise === '1' ? '覆水' :info. resultRevise === '2' ? '结冰' : '积雪'}}
                        </span>
                        <span v-else>
                            {{info. resultRevise === '0' ? '干燥' :'未检测到'}}
                        </span>
                    </van-col>
                    <van-col span="12">置信度: {{info && info.cf || 0}}</van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="12">温度：{{info && info.airTemp !== null ? info.airTemp.toFixed(1) : '-'}} ℃</van-col>
                    <van-col span="12">湿度：{{info && info.airHumi !== null ? info.airHumi :  '-'}} %</van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="12">风向：{{ toFormatWindDir(info.windDirection)}} </van-col>
                    <van-col span="12">
                        <span  v-if="info.windSpeed === -1 || info.windSpeed === null">
                            风速：- -
                        </span>
                        <span v-else>
                            风速：{{ info.windSpeed }} m/s
                        </span>
                    </van-col>
                </van-row>
                <van-row class="block">
                    <van-col span="12">大气压：{{info && info.atmoPressure !== null ? (info.atmoPressure / 100) : '-'}} hPa</van-col>
                </van-row>
                
            </div>
            <!-- 告警 -->
            <div v-if="base.roadType == 2 && alarmInfo.content">
                <div class="flex-between">
                    <div class="title">
                        告警信息
                    </div>
                    <div class="time">
                        <van-icon name="clock-o" />
                        {{alarmInfo.updateTime || '0000-00-00 00:00:00'}}
                    </div>
                </div>
                <div class="content bg-alarm">
                    <van-row class="block">
                        <van-col span="24">告警级别：{{alarmInfo.level || '无'}}</van-col>
                    </van-row>
                    <van-row class="block">
                        <van-col span="24">告警内容：{{alarmInfo.content}}</van-col>
                    </van-row>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="btn" @click="refresh"><van-icon name="replay" :class="{'refresh':refreshBtn}" />刷新数据</div>
        </div>
    </div>
</template>

<script>
import { ImagePreview } from 'vant';
let Hls = require('hls.js');
export default {
    data(){
        return{
            info:{
                airTemp:0,
                windSpeed:0,
                atmoPressure:0
            },
            base:{
                roadName:'',
            },
            alarmInfo:{
                type:null,
                content:null,
                updateTime:null
            },
            refreshBtn:false
        }
    },
    mounted(){
        const info = window.sessionStorage.getItem('currentRoadInfo') ? JSON.parse(window.sessionStorage.getItem('currentRoadInfo')) : void 0;
        this.base = { ...info };
        if(info.roadType == 2){
            if(info.camera && info.camera.comment){
                this.timer = setTimeout(() => {
                    this.getStream(info.camera.comment);
                },500);
                
            }
            if(info.melve && info.melve.melvesStatus){
                this.info = info.melve.melvesStatus;
            }
        }
       // this.getNewInfo()
    },
    beforeDestroy(){
        this.videoHlsDestroyed();
    },
    methods:{
        // 预览图片
        previewImg(url){
            ImagePreview([url]);
        },
        refresh(){
            this.refreshBtn = true;
            this.getNewInfo();
            setTimeout(()=>{
                this.$toast('刷新成功');
                this.refreshBtn = false;
            },800)
        },
        // 获取hls视频流
        getStream(source) {
            

            if (Hls.isSupported()) {
                this.hls = new Hls();
                this.hls.loadSource(source);
                this.hls.attachMedia(this.$refs.video);

                this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    console.log("加载成功");
                    
                        this.$refs.video.play();
                    
                    
                });
            }else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
                // 以下逻辑兼容IOS浏览器内核
                this.$refs.video.src = source;
                this.$refs.video.addEventListener('loadedmetadata',function() {
                    this.$refs.video.play();
                });
            }
        },
        // 获取当前设备最新信息
        getNewInfo(){
            this.$api.RXROAD.realInfoByDevCode(
                this.$route.query.devCode
            ).then( d => {
                this.info = { ...d };
            })

            this.$api.RXROAD.alarmPageList(1,1,{
                devCode:this.$route.query.devCode,
                projectCode:window.sessionStorage.getItem('projectCode'),
                status:0
            }).then( d => {
                if(d.length > 0){
                    this.alarmInfo = { ...d[0]}
                }
            })
        },
        toFormatWindDir(v){
            let text = '';
            v == 1 ? text='北风' :
            v == 2 ? text='东北风' :
            v == 3 ? text='东风' :
            v == 4 ? text='东南风' :
            v == 5 ? text='南风' :
            v == 6 ? text='西南风' :
            v == 7 ? text='西风': 
            v == 8 ? text='西北风': text='- -' ;
            return text;
        },
        videoHlsDestroyed(){
            if (this.hls) {
                this.$refs.video.pause();
                this.hls.destroy();
                this.hls = null;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.home{
    .head-img{
        width: 100%;
        padding: 15px 15px 0 15px;
        box-sizing: border-box;
        .img{
            width: 100%;
        }
    }
    .body-info{
        padding: 10px 15px;
        .title{
            border-left: 4px solid #23b593;
            padding-left: 10px;
            margin: 12px 0;
            text-align: left;
            font-weight: bolder;
            font-size: .95rem;
        }
        .content{
            border: 1px solid #f0f0f0;
            border-radius: 10px;
            background-color: #39a29d;
            text-align: left;
            padding: 10px 20px;
            font-size: 0.9rem;
            color: #fff;
            box-shadow: 2px 2px 2px #efefef;
            .block{
                padding:8px 0;
                .important-font{
                    font-weight: bolder;
                    color: #e03e00;
                }
            }
        }
        .bg-alarm{
            background-color: #ec7569;
        }
    }
    .footer{
        display: flex;
        justify-content: center;
        margin: 20px;
        .btn{
            background: #23b593;
            border-radius: 10px;
            color: #fff;
            font-size: .9rem;
            line-height: 2.5;
            width: 45%;
            text-align: center;
            &:active{
                opacity: .7;
            }
        }
        .refresh{
            transition: all 0.8s;
            transform: rotate(360deg);
        }
    }
    .flex-between{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .time{
            font-size: .78rem;
            color: #adadad;
        }
    }
}
</style>